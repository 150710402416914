/**
 * Функция отображения фото на весь экран
 * @param photo Строка содержащая имя файла фотографии
 */
var $ = require('jquery');

function viewPhoto(photo) {
    this.view = function(){
        let bg = document.createElement('div');
        let block = document.createElement('div');
        let img = document.createElement('img');
        let close = document.createElement('a');
        bg.style.width =  '100%';
        bg.style.height = '100%';
        bg.style.position = 'fixed';
        bg.style.left = '0px';
        bg.style.top = '0px';
        bg.style.background = 'rgba(0, 0, 0, 0.7)';
        document.body.appendChild(bg);
        img.style.position = 'absolute';
        img.style.top = '50%';
        img.style.left = '50%';
        img.style.transform = 'translate(-50%, -50%)';
        img.style.padding = '10px';
        $(img).attr({'src':photo});
        img.onload = function () {
            if (document.documentElement.clientHeight > document.documentElement.clientWidth) {
                $(img).attr({'width':'90%'});
            } else {
                $(img).attr({'height':'90%'});
            }
            let imgCoords = img.getBoundingClientRect();
            console.log(imgCoords.toJSON());
            close.style.transform = 'translate('+ ((imgCoords.width/2)-100) + 'px, '+ ((imgCoords.height/2*(-1))+10) +'px)';
        }

        //Закрытие фото по Escape
        document.addEventListener('keydown', function (event) {
            if (event.code == 'Escape'){
                bg.remove();
            }
        });

        bg.appendChild(img);
        close.style.position = 'absolute';
        close.style.top = '50%';
        close.style.left = '50%';
        close.innerText = 'Закрыть';
        close.onclick = function(){
            bg.remove();
        }
        bg.appendChild(close);
    }
}
export {viewPhoto};

