import {URL} from "./contants";
import {SPIN} from "./contants";

/**
 * JavaScript записи и отправки голосового сообщения
 */
function recordVoice() {
    this.init = function () {
        navigator.mediaDevices.getUserMedia({audio: true})
            .then(stream => {
                const mediaRecorder = new MediaRecorder(stream);
                let voice = [];
                let btn = document.querySelector('#load-one-photo-voice');
                btn.addEventListener('mousedown', function () {
                    mediaRecorder.start();
                });
                btn.addEventListener('mouseup', function () {
                    mediaRecorder.stop();
                });
                btn.addEventListener('touchstart', function () {
                    mediaRecorder.start();
                });
                btn.addEventListener('touchend', function () {
                    //console.log('touchend');
                    mediaRecorder.stop();
                });

                mediaRecorder.addEventListener("dataavailable", function (event) {
                    voice.push(event.data);
                });

                mediaRecorder.addEventListener("stop", function () {
                    SPIN.spin(document.body);
                    const voiceBlob = new Blob(voice, {
                        type: 'audio/wav'
                    });


                    let id = document.getElementById('load-one-photo-id').value;
                    let fd = new FormData();
                    fd.append('voice', voiceBlob);
                    fd.append('id', id);
                    voice = [];
                    sendVoice(fd);
                });
            });
    }
}
export {recordVoice};
/**
 * Функция отправки записанного сообщение в теле формы
 * @param form Форма содержащее поле с записаным звуком
 * @returns {Promise<void>}
 */
async function sendVoice(form) {
    let link = URL + 'voice' + '/add';
    let promise = await fetch(link, {
        method: 'POST',
        body: form});
    if (promise.ok) {
        SPIN.stop();
        let response =  await promise.json();
        let audio = document.createElement('audio');
        audio.src = response.data;
        audio.controls = true;
        audio.autoplay = true;
        document.querySelector('#voices').innerHTML = '';
        document.querySelector('#voices').appendChild(audio);
    }
}
