var $ = require('jquery');
import {getPhoto} from './getPhoto.js';
import {URL} from './contants';
import {SPIN} from './contants';

function sendFile(file) {
    this.send  = function () {
        SPIN.spin(document.body);
        let link = URL + 'photo/add';
        let form = new FormData();
        let id_defect = $('input#load-one-photo-defect').val();
        let id_act = $('input#load-one-photo-act').val();
        if (file.length == 1) {
            form.append('photo', file[0]);
        } else if (file.length > 1) {
            for (let i = 0; i < file.length; i++) {
                form.append('photo[]', file[i]);
            }
        }
        form.append('id_defect', id_defect);
        form.append('id_act', id_act);
        sendtoserver(link, form, id_defect, id_act, file.length);
    }
}
export {sendFile};

async function sendtoserver(link, form, id_defect, id_act, file_count) {
    let promise = await fetch(link, {
        method: 'POST',
        body: form});
    if (promise.ok) {
        SPIN.stop();
        let response =  await promise.json();
        let file = response.data[0]['file'];
        let file_type = file.split('/');
        if (file_type[0] == 'media') {
            let newCount = parseInt($('#count-'+id_defect).html()) + 1;
            $('#count-'+id_defect).html(newCount);
            var gf = new getPhoto(id_defect+ '-' + id_act, false).get();
            console.log(file_type);
            return;
        }

        if (file_count == 1) {
            $('input#load-one-photo-id').val(response.data[0]['id'])
            $('img#load-one-photo').attr('src', '../'+response.data[0]['file']);
            $('#bg-load-photo-new').fadeIn(500);
        } else if (file_count > 1) {
            let newCount = parseInt($('#count-'+id_defect).html()) + file_count;
            $('#count-'+id_defect).html(newCount);
            var gf = new getPhoto(id_defect+ '-' + id_act, false).get();
        }
    }
}