//Поиск по вин-коду
var $ = require('jquery');
import {URL} from './contants';
/**
 * Поиск с выпадающим списком
 * @param elem поле поиска input
 * @param list блок div для отображения всплывающего списка
 */
function searchDropdown(elem, list) {
    this.init = function () {
        $(elem).keyup(function(){
            $(list).html('');
            if(elem.value.length>0) {
                $.getJSON(URL+"search/vin?vin="+this.value, function(data) {
                    $.each(data, function (key, val) {
                        let div_element = document.createElement('div');
                        div_element.style.padding = "3px";
                        div_element.style.position = 'relative'
                        div_element.style.backgroundColor = "#FFFFFF";
                        div_element.style.borderRadius = "0 0 5px 5px";
                        div_element.style.cursor = "pointer";
                        div_element.id = "elem-" + val['id'];
                        div_element.onclick = function () {
                            window.location.href="../car/search?id_car="+val['id'];
                        };
                        div_element.innerHTML = val['vin'];
                        $(list).append(div_element);
                    });
                });
            } else {
                $(list).html('');
            }
        });
    }
}
export {searchDropdown};