//Отображение-скрытие партий в списке клиентов
var $ = require('jquery');
function showBatchs (id) {
    this.click = function () {
        console.log(id);
        if ($('button#'+id).html() == 'Показать партии') {
            $('button#'+id).html('Скрыть партии');
        } else {
            $('button#'+id).html('Показать партии');
        }
        $('div #'+id+'.container').slideToggle(500);
    }
}
export {showBatchs};