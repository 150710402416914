import {URL} from "./contants";
import {SPIN} from "./contants";

/**
 * JavaScript записи и отправки общего голосового сообщения
 */
function recordGeneralVoice() {
    this.init = function () {
        navigator.mediaDevices.getUserMedia({audio: true})
            .then(stream => {
                const mediaRecorder1 = new MediaRecorder(stream);
                let voice = [];
                let btn = document.querySelector('#load-general-voice');
                btn.addEventListener('mousedown', function () {
                    mediaRecorder1.start();
                });
                btn.addEventListener('mouseup', function () {
                    mediaRecorder1.stop();
                });
                btn.addEventListener('touchstart', function () {
                    mediaRecorder1.start();
                });
                btn.addEventListener('touchend', function () {
                    mediaRecorder1.stop();
                });

                mediaRecorder1.addEventListener("dataavailable", function (event) {
                    voice.push(event.data);
                });

                mediaRecorder1.addEventListener("stop", function () {
                    console.log("stop");
                    SPIN.spin(document.body);
                    const voiceBlob = new Blob(voice, {
                        type: 'audio/wav'
                    });


                    let id = document.getElementById('id_act').value;
                    let fd = new FormData();
                    fd.append('voice', voiceBlob);
                    fd.append('id', id);
                    voice = [];
                    //mediaRecorder1.stream.getAudioTracks().forEach(function(track){track.stop();});
                    sendGeneralVoice(fd);
                });
            });
    }
}
export {recordGeneralVoice};
/**
 * Функция отправки записанного сообщение в теле формы
 * @param form Форма содержащее поле с записаным звуком
 * @returns {Promise<void>}
 */
async function sendGeneralVoice(form) {
    let link = URL + 'act' + '/addGeneralVoice';
    let promise = await fetch(link, {
        method: 'POST',
        body: form});
    if (promise.ok) {
        SPIN.stop();
        let response =  await promise.json();

        window.location = window.location.href;
        //document.getElementById('act-form').submit();

    }
}
