//Подключение стилей
import '../css/spin.css';
import '../css/app.css';

//Подключение Bootstrap
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

//Подключение Vue.js
import Vue from 'vue';
import act from './vue/template.vue'

//Подключение модулей
var $ = require('jquery');
import {SPIN, VOICE, GENERAL_VOICE} from "./contants";
import {MyMessage} from './message';
import {showBatchs} from './show-batchs';
import {getPhoto} from './getPhoto';
import {sendFile} from './sendFile.js';
import {searchDropdown} from './search-dropdown.js';
import {viewPhoto} from "./view-photo";

$(document).ready(function(){

    // if (document.getElementById('load-one-photo-id')) {
    //     VOICE.init();
    // }
    // if (document.getElementById('load-general-voice')) {
    //     GENERAL_VOICE.init();
    // }

    //Компонент шаблона акта
    if (document.getElementById('template')){
        console.log('Создание компонента')
        new Vue({
            el: '#template',
            template: '<act/>',
            components: { act }
        });
    }

    //Инициализаци поиска
    let searchInput = document.getElementById('str-fnd');
    let searchDiv = document.getElementById('hint-vin');
    let sd = new searchDropdown(searchInput, searchDiv).init();

    //Отображение выпадающего списка клиентов при регистрации пользователя
    if ($('#InputRole').val() != "CLIENT") {
        $('p#SelectClient').hide();
    }
    $('#InputRole').change(function () {
        if ($('#InputRole').val() == "CLIENT") {
            $('p#SelectClient').show();
        } else {
            $('p#SelectClient').hide();
        }
    });

    //Отображение партий
    $('button.show-batch').click(function () {
        var sb = new showBatchs(this.id).click();
    });
    //Отображение списка фотографий
    $("button.link-photo").click (function () {
        var gp = new getPhoto(this.id, true).get();
    });

    //Автоматическая загрузка сканов акта на сервер
    $('#load_act_front').click(function () {
        $('#scan-front').click();
    });
    $('#load_act_back').click(function () {
        $('#scan-back').click();
    });
    $('.scan_load').change(function () {
        SPIN.spin(document.body);
        $('#reload-form').val('1');
        $('#act-form').submit();
    });

    //Изменение надписи кнопки загрузки сканированного акта
    if ($('#img-scan-front').attr('src') != '') $('#load_act_front').html('Изменить');
    if ($('#img-scan-back').attr('src') != '') $('#load_act_back').html('Изменить');

    // Увеличения фото акта
    $('#img-scan-front').click(function () {
       let vw = new viewPhoto($('#img-scan-front').attr('src')).view();
    });
    $('#img-scan-back').click(function () {
        let vw = new viewPhoto($('#img-scan-back').attr('src')).view();
    });

    //Открытие окна записи голосового комментария
    $("#add-general-voice").click (function () {
        $("#bg-add-general-voice").removeClass('d-none');
        GENERAL_VOICE.init();
    });
    //Открытие окна записи голосового комментария
    $("#load-general-voice-cancel").click (function () {
        $("#bg-add-general-voice").addClass('d-none');
    });

    //Открытие окна выбора файлов фото
    $("a.photo-list-add").click (function () {
        VOICE.init();
        document.querySelector('#voices').innerHTML = '';
        let id = this.id.split('-');
        $('input#load-one-photo-defect').val(id[0]);
        $('input#load-one-photo-act').val(id[1]);
        $('#load-list-photo').click();
    });

    //Открытие окна выбора файла медиа
    $("a.media-add").click (function () {
        //document.querySelector('#voices').innerHTML = '';
        let id = this.id.split('-');
        $('input#load-one-photo-defect').val(id[0]);
        $('input#load-one-photo-act').val(id[1]);
        $('#load-media').click();
    });

    //Отправка фото
    $("input#load-list-photo").change(function () {

        let in_file = document.getElementById("load-list-photo");
        if ((in_file.files).length > 0)
            var sf = new sendFile($("input#load-list-photo").prop('files')).send();
    });

    //Отправка медиа
    $("input#load-media").change(function () {

        console.log('Media selected');
        let in_file = document.getElementById("load-media");
        if ((in_file.files).length > 0)
            var sf = new sendFile($("input#load-media").prop('files')).send();
    });

    //Сохранение комментария
    $("button#load-one-photo-save").click (function () {
        console.log('Сохранение коментария');
        document.querySelector('#voices').innerHTML = '';
        if ($('input#load-one-photo-comment').val() != '') {
            let comment = $('input#load-one-photo-comment').val();
            $('input#load-one-photo-comment').val(null);
            let id = $('input#load-one-photo-id').val();
            $('input#load-one-photo-id').val(null)
            sendComment(id, comment);
        }
        let id_act = $('input#load-one-photo-act').val();
        let id_defect = $('input#load-one-photo-defect').val();
        let newCount = parseInt($('#count-'+id_defect).html()) + 1;
        $('#count-'+id_defect).html(newCount);
        let gp = new getPhoto(id_defect + '-' + id_act, false).get();
        $('#bg-load-photo-new').fadeOut(500);
    });

    //Проверка подтверждения пароля
    $('#InputConfirmation').keyup(function(){
        comformationPasswod();
    });
    $('#InputPassword').keyup(function(){
        comformationPasswod();
    });
});


/**
 * Функция проверки подтерждения пароля
 */
function comformationPasswod() {
    $('#submitRegistration').attr({'disabled':true});
    if ($('#InputPassword').val() == $('#InputConfirmation').val()) {
        $('#submitRegistration').attr({'disabled':false});
    }
}

/**
 * Функция отправки комментария
 * @param id Идентификатор фотографии
 * @param comment Текст комментария
 * @returns {Promise<void>}
 */
async function sendComment(id, comment) {
    let link = URL + 'comment' + '/add';
    let form = new FormData();
    form.append('id', id);
    form.append('comment', comment);
    let promise = await fetch(link, {
        method: 'POST',
        body: form});
}


