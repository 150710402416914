/**
 * Фунция получения фото с сервера
 * @param id_act ID акта
 * @param id_defect ID дефекта
 */

var $ = require('jquery');
import {URL} from './contants.js';
import {viewPhoto} from "./view-photo";

function getPhoto(id, flag_show) {
    this.get = function () {
        let id_arr = id.split('-');
        let id_defect = id_arr[0];
        let id_act = id_arr[1];
        //Очистка раскрывающегося списка
        $('#div-photo'+id_defect).html('');

        $.ajax({
            url:URL+'photo/get?id_act='+id_act+'&id_defect='+id_defect,
            success:function (data) {
                console.log(URL);
                if (data['result'] == 'OK') showPhoto(data['data'], id_defect);
            }
        });
        if (flag_show) {
            $('#tr-photo' + id_defect).slideToggle(0);
            $('#div-photo' + id_defect).slideToggle(100);
        }
    }
}
export {getPhoto};

/**
 * Фунция отображения фотографий полученных с сервера через ajax
 * @param data Массив с фотографиями
 * @param id_defect ID дефекта
 */
function showPhoto(data, id_defect) {
    $('#div-photo'+id_defect).html('');
    data.forEach(function(photo){
        addPhoto(photo);
    })
}

/**
 * Функция добавление одной фотографии
 * @param photo Массив с данными фотографии
 */
function addPhoto(photo) {
    let photoDiv = document.createElement('div');
    let photoLink = document.createElement('a');
    let photoImg = document.createElement('img');
    let photoComment = document.createElement('p');
    photoDiv.className = 'show-photo d-inline-block text-center mb-auto col-11 col-lg-3 mt-2';
    photoLink.className = 'cursor-pointer show-photo';
    photoLink.id = '123';
    photoLink.onclick = function () {
        let vp = new viewPhoto(photo['photo']).view();
    };
    if (photo['photo']=="" || photo['photo']==null) photo['photo'] = "../images/no-photo.png";
    photoImg.src = photo['photo'];
    photoImg.className = 'w-100';
    photoComment.innerHTML = photo['memo'];
    $('#div-photo'+photo['id_defect']).append(photoDiv);
    $(photoDiv).append(photoLink);
    $(photoLink).append(photoImg);
    $(photoLink).append(photoComment);
    if (photo['voice']!=null) {
        let photoVoice = document.createElement('audio');
        photoVoice.src = photo['voice'];
        photoVoice.controls = true;
        photoVoice.autoplay = false;
        $(photoDiv).append(photoVoice);
    }
}