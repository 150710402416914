
function message(msg) {
    this.show = function () {
        let block = document.createElement('div');
        block.style.position = 'absolute';
        block.style.color = '#FFF';
        block.style.background = 'rgba(0,0,0,0.8)';
        block.style.borderRadius = '10px';
        block.style.top = '50%';
        block.style.left = '50%';
        block.style.transform = 'translate(-50%, -50%)';
        block.style.padding = '10px';
        block.innerHTML = msg;
        document.body.appendChild(block);
        setTimeout(() => block.remove(), 1000);
    }
}
module.exports = message;